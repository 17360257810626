import React, { useEffect, useState } from 'react';

// DEPENDENCIES

// COMPONENTS
import { Block } from './Block';
import Navigation from './Navigation';
import Choices from './PreviewChoices';

// STYLES

export default function Question(props) {
  const { logic, submitted, answerEncounter } = props;

  const [isCorrect, setIsCorrect] = useState();
  useEffect(() => {
    if (submitted) {
      const activeQuestionId = logic.active.question.id;
      const selectedChoiceObject = logic.selectedChoices.find((choice) => {
        return choice.questionId == activeQuestionId;
      });
      const selectedChoice = selectedChoiceObject.selectedChoice;

      if (selectedChoice && selectedChoice.correctAnswer) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }
    }
  }, [logic.active]);

  const selectedChoiceObj = logic.selectedChoices.filter(
    (o) => o.questionId == logic.active.question.id,
  );

  const selectedChoice = selectedChoiceObj[0].selectedChoice;

  const correctAnswer = logic.active.question.choiceSet.find(
    (choice) => choice.correctAnswer == true,
  );

  return (
    <>
      <Block>
        <div className="document-title">
          Question{' '}
          <span>
            {logic.length > 1 && `${logic.active.order + 1} of ${logic.length}`}
          </span>
        </div>
        <div style={{ marginBottom: 25 }} className="serif">
          {logic.active.question.text}
        </div>

        <Choices
          {...props}
          selectedChoice={selectedChoice}
          correctAnswer={correctAnswer}
          isCorrect={isCorrect}
        />

        <Navigation
          {...props}
          answerEncounter={answerEncounter}
          selectedChoice={selectedChoice}
          correctAnswer={correctAnswer}
        />
      </Block>
    </>
  );
}
