import React, { useEffect, useState } from "react";

import Editor, { composeDecorators } from "draft-js-plugins-editor";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import createImagePlugin from "draft-js-image-plugin";
import createAlignmentPlugin from "draft-js-alignment-plugin";
import createFocusPlugin from "draft-js-focus-plugin";
import createLinkPlugin from "draft-js-anchor-plugin";
import createVideoPlugin from "draft-js-video-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";

import Immutable from "immutable";

import {
  convertFromRaw,
  EditorState,
  DefaultDraftBlockRenderMap,
} from "draft-js";
import "draft-js/dist/Draft.css";

import styled from "styled-components";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import "draft-js-image-plugin/lib/plugin.css";
import "draft-js-alignment-plugin/lib/plugin.css";
import "draft-js-anchor-plugin/lib/plugin.css";
import "draft-js-focus-plugin/lib/plugin.css";
import "draft-js-video-plugin/lib/plugin.css";
import "draft-js-linkify-plugin/lib/plugin.css";

const staticToolbarPlugin = createToolbarPlugin();
const linkPlugin = createLinkPlugin();
const linkifyPlugin = createLinkifyPlugin();
const videoPlugin = createVideoPlugin();
const focusPlugin = createFocusPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;
const decorator = composeDecorators(alignmentPlugin.decorator);
const imagePlugin = createImagePlugin({ decorator });

export default function MedzcoolEditor(props) {
  const { content } = props;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (content) {
      const fetchedState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );
      setEditorState(fetchedState);
    }
  }, [content]);

  const plugins = [
    staticToolbarPlugin,
    imagePlugin,
    focusPlugin,
    alignmentPlugin,
    linkPlugin,
    videoPlugin,
    linkifyPlugin,
  ];

  const blockRenderMap = Immutable.Map({
    unstyled: {
      element: "section",
    },
  });

  // Include 'paragraph' as a valid block and updated the unstyled element but
  // keep support for other draft default block types
  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
    blockRenderMap
  );

  function myBlockStyleFn(contentBlock) {
    const type = contentBlock.getType();
    if (type === "unstyled") {
      return "default-block";
    }
  }

  return (
    <>
      <EditorContainer readOnly>
        <Editor
          readOnly
          onChange={setEditorState}
          editorState={editorState}
          plugins={plugins}
          blockRenderMap={extendedBlockRenderMap}
          blockStyleFn={myBlockStyleFn}
          className="editor"
        />
      </EditorContainer>
    </>
  );
}

const EditorContainer = styled.div`
  .DraftEditor-root {
    border: ${(props) => (props.readOnly ? `none` : `1px solid lightgray`)};
    border-radius: ${(props) => (props.readOnly ? `none` : `5px`)};
    padding: ${(props) => (props.readOnly ? `none` : `15px`)};
    figure {
      margin: 10px 0;
      img {
        max-width: 100%;
      }
    }
    .default-block {
      margin-bottom: 15px;
    }
  }
`;
const ImageButton = styled.button`
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  outline: 0;
  &:hover {
    background: #f3f3f3;
    outline: 0;
  }
`;
