import React from 'react';

// DEPENDENCIES
import { Link } from 'react-router-dom';

// COMPONENTS
import { SolidButton } from './Buttons';

// STYLES
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

export default function Header(props) {
  const { hashId, exitLocation } = props;
  return (
    <HeaderContainer>
      {exitLocation ? (
        <SolidButton className="back-container" onClick={exitLocation}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </SolidButton>
      ) : (
        <SolidButton
          className="back-container"
          onClick={() => props.history.push(`/edit/${hashId}`)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </SolidButton>
      )}
    </HeaderContainer>
  );
}
const HeaderContainer = styled.div`
  position: fixed;
  padding: 0 15px;
  left: 0px;
  top: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  z-index: 98;
  height: 50px;
  display: grid;
  align-content: center;
  .back-container {
    color: black;
    background: transparent;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding-left: 15px;
    padding-right: 20px;
    height: inherit;
    font-size: 18px;
  }
  @media (max-width: 768px) {
    .back-container {
      position: inherit;
      padding-left: 25px;
      left: 0;
    }
  }
`;
