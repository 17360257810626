import React, { Fragment, lazy, Suspense, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

import ReactGA from "react-ga";

// Dependencies
import axios from "axios";

// Components
import Header from "./Header/Header";
import Spinner from "./Utilities/Spinner";
import Collection from "./Collection/newCollection";
import OldCollection from "./Collection/oldCollection";
import CollectionsList from "./Home/CollectionsList";
import CaseDetails from "./CaseDetails/CaseDetails";
import Home from "./Home/Home";
import EncounterRouter from "./Encounters/EncounterRouter";

// Dependencies
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Apollo, GraphQL
// import { Query } from "react-apollo";
// import gql from "graphql-tag";

// Apollo, GraphQL
import { gql, useQuery } from "@apollo/client";

const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      username
      email
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
          priceId
        }
        subscribedRounds {
          id
          collectionTitle
          description
          checkoutThumbnail
          collectionUrl
        }
        latestInvoiceId
        subscriptionStatus
        paymentIntentStatus
        stripeCustomerId
        subscriptionId
        priceId
        productId
        stripeCustomerPaymentMethodId
        stripeExpMonth
        stripeExpYear
        stripeLast4
        stripeCardBrand
        custom
      }
    }
  }
`;
ReactGA.initialize("UA-149755134-1");

export default function App(props) {
  function handleGAEvent(category, action, label) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }

  // fetch("http://localhost:3000/api/testing").then(async (response) => {
  //   const data = await response.json();
  //   console.log(data);
  // });

  const { loading, error, data } = useQuery(GET_USER);

  if (loading) return null;
  if (error) return <h1>Server Error</h1>;
  const user = data.user;

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Home {...props} user={user} handleGAEvent={handleGAEvent} />
            )}
          />
          <Route
            path="/clinic"
            render={(props) => {
              window.location = "https://clinic.medzcool.com";
              return null;
            }}
          />

          <Route
            exact
            path="/collections"
            render={(props) => (
              <CollectionsList
                {...props}
                user={user}
                handleGAEvent={handleGAEvent}
              />
            )}
          />
          <Route
            exact
            path="/collection/:collectionUrl/:caseId?"
            render={(props) => (
              <Collection
                {...props}
                user={user}
                handleGAEvent={handleGAEvent}
              />
            )}
          />
          <Route
            exact
            path="/legacy-collection/:collectionUrl/:caseId?"
            render={(props) => (
              <OldCollection
                {...props}
                user={user}
                handleGAEvent={handleGAEvent}
              />
            )}
          />
          <Route
            path="/purchase/collection/:collectionUrl"
            render={(props) => (
              <Collection
                {...props}
                user={user}
                handleGAEvent={handleGAEvent}
                purchase
              />
            )}
          />
          <Route
            exact
            path="/case/:caseId"
            render={(props) => (
              <EncounterRouter
                {...props}
                user={user}
                handleGAEvent={handleGAEvent}
              />
            )}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}
