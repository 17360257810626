import React, { Fragment, useState } from "react";

import styled from "styled-components";
import { fontFamily } from "../Cytoplasm/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import createEvent from "../Events/createEvent";
import mixpanel from "mixpanel-browser";
import { mixpanelToken } from "../settings";

mixpanel.init(mixpanelToken);

export default function PublicCTA(props) {
  const { user, encounter } = props;

  const [visible, setVisible] = useState(true);

  function learnMore() {
    // console.log('create event');
    let userId;
    if (user) {
      userId = user.id;
    } else {
      userId = null;
    }

    createEvent(
      userId,
      "rounds",
      "call to action",
      "clicked",
      "clinic",
      "When user clicks on the Clinic Learn More call to action after they have answered a public encounter"
    )
      .then((result) => {
        setVisible(false);
        mixpanel.track("Call To Action", {
          App: "Rounds",
          Location: "Floating Bottom",
          "Encounter ID": encounter.id,
          "Encounter Hash ID": encounter.hashId,
          "CTA For": "Clinic",
          "Redirect URL": "medzcool.com/clinic",
        });
      })
      .then(() => (window.location = "https://medzcool.com/clinic"));
  }

  return (
    <>
      {visible && (
        <StickyFooter>
          <div className="sticky-inner">
            <div className="sticky-text">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div>
                <h3 className="sticky-title">Looking for more cases?</h3>
                <div className="stick-text">
                  Get new medical encounters every week on our{" "}
                  <strong>Clinic</strong> app.
                </div>
              </div>
              <div className="learn-more" onClick={learnMore}>
                Learn More
              </div>
            </div>
          </div>
        </StickyFooter>
      )}
    </>
  );
}
const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  padding-bottom: 25px;
  color: white;
  font-family: ${fontFamily.sansSerif};
  z-index: 100;
  .sticky-inner {
    max-width: 750px;
    margin: auto;
    margin: auto;
    background: #0d151d;
    padding: 15px 50px 15px 35px;
    border-radius: 50px;
    box-shadow: rgb(0 0 0 / 75%) 0px 5px 13px;
  }
  .sticky-text {
    display: grid;
    margin: auto;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: 25px;
    padding-bottom: 5px;
    font-size: 18px;
  }
  .sticky-title {
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 22px;
  }
  .learn-more {
    border: 1px solid #f7ce66;
    border-radius: 5px;
    padding: 8px 15px;
    background-color: #f7ce66;
    color: black;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    padding: 8px;
  }
  @media (max-width: 425px) {
    padding-bottom: 18px;
    .sticky-inner {
      padding: 15px;
      border-radius: 25px;
    }
    .sticky-title {
      font-size: 16px;
      margin-bottom: 3px;
    }
    .sticky-text {
      grid-gap: 15px;
      font-size: 14px;
      line-height: 120%;
    }
  }
`;
