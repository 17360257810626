import React, { useState, useEffect, Fragment, Suspense, lazy } from "react";
import { url } from "../settings";
import ReactGA from "react-ga";

import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";

// Dependencies
import axios from "axios";

// Components
import CaseStem from "./CaseStem";
import CaseCallToAction from "./CaseCallToAction";
import CaseQuestionAnswer from "./CaseQuestionAnswer";
import Spinner from "../Utilities/Spinner";
import CaseFooter from "./CaseFooter";
import ClinicalCaseCard from "./ClinicalCaseCard";
import CaseDetailsHeader from "../Header/CaseDetailsHeader";
import HorizontalResponsiveAd from "../Ads/HorizontalResponsiveAd";
import FullResponsive from "../Ads/FullResponsive";
import clinicPromo from "./clinic-case-promo.jpg";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faAward,
} from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { blue } from "../Utilities";

// Styles
import styled from "styled-components";
import {
  purple,
  green,
  red,
  grayscale,
  fontFamily,
  fontWeight,
  LoadingContainer,
  handleGAEvent,
  shuffle,
} from "../Utilities";

// Data
import { featuredCasesData } from "../FeaturedCasesData";

const CaseChoices = lazy(() => import("./CaseChoices"));
const CaseExplanation = lazy(() => import("./CaseExplanation"));

export default function CaseDetails(props) {
  const { user } = props;
  const caseId = props.match.params.caseId;

  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, [props.location.pathname]);

  const { collection, handleCaseAnswerState, handleGAEvent } = props;

  const [isInFeaturedCases, setIsInFeaturedCases] = useState(false);
  const featuredCases = shuffle(featuredCasesData.data.featuredCases);

  useEffect(() => {
    featuredCases.map((caseObj) => {
      if (caseObj.id == caseId) {
        setIsInFeaturedCases(true);
      }
    });
  }, []);

  const [caseObject, setCaseObject] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios({
      method: "post",
      url: `${url}/cases/fetch-case/`,
      data: {
        caseId: caseId,
      },
    }).then(function (response) {
      setCaseObject(response.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    setCaseObject();
    setSelectedChoice();
    setAnswered(false);
    setCorrect();

    axios({
      method: "post",
      url: `${url}/cases/fetch-case/`,
      data: {
        caseId: caseId,
      },
    }).then(function (response) {
      setCaseObject(response.data.data);
      setLoading(false);
    });
  }, [caseId]);

  const [selectedChoice, setSelectedChoice] = useState();
  function handleChoiceChange(choice) {
    if (!answered) {
      setSelectedChoice(choice.id);
    }
  }

  const [answered, setAnswered] = useState(false);
  const [correct, setCorrect] = useState();

  function handleAnswer(choices, layout) {
    window.scrollTo(0, 0);

    handleGAEvent && handleGAEvent("Case", "Answered", caseId);

    // 1. Get the correct answer choice
    const correctChoice = choices.find((choice) => {
      return choice.correct_answer === true;
    });

    // 2. Compare user's selected choice with the correct answer choice
    // 3. Set case state to 'answered'
    if (selectedChoice == correctChoice.id) {
      setCorrect(true);
      if (collection) {
        handleCaseAnswerState("correct", caseId);
      }
    } else {
      if (collection) {
        handleCaseAnswerState("incorrect", caseId);
      }
    }
    setAnswered(true);
  }

  // when user selects a related case, resent casedetails states
  function handleRelatedCase() {
    setAnswered(false);
    setCorrect();
    setSelectedChoice();
  }

  function goBack() {
    props.history.goBack();
  }

  function createMarkup(html) {
    return { __html: html };
  }

  function createDraftText(caseObject) {
    if (caseObject.caseType == "clinical scenario") {
      return caseObject.stems[0].stem;
    } else if (caseObject.caseType == "question") {
      return caseObject.questions[0].question;
    } else {
      return null;
    }
  }

  function handleClinicAd(label) {
    handleGAEvent("Clinic", "Clicked", label);
    window.location = "https://medzcool.com/clinic";
  }

  if (loading)
    return (
      <LoadingContainer>
        <CaseDetailsHeader user={props.user} {...props} />
        <Spinner />
      </LoadingContainer>
    );

  if (!collection && !isInFeaturedCases) {
    console.log("clicked");
    handleGAEvent("Case", "Unauthorized View", caseId);
    window.location = "https://rounds.medzcool.com";
    return null;
  }

  return (
    <Fragment>
      <CaseDetailsHeader
        user={props.user}
        {...props}
        correct={correct}
        answered={answered}
        collection={collection}
      />
      <CaseMainContainer answered={answered} correct={correct}>
        <CaseSubContainer answered={answered}>
          <MainCaseBlock>
            <CaseStem
              caseType={caseObject.caseType}
              answered={answered}
              caseLayout={caseObject.layout}
              oldStem={createMarkup(caseObject.clinicalScenario)}
              stem={createDraftText(caseObject)}
              oldQuestion={createMarkup(caseObject.clinicalScenario)}
              question={createDraftText(caseObject)}
            />

            {!collection && answered && (
              <img
                src={clinicPromo}
                onClick={() => handleClinicAd("Details Ad")}
                style={{ width: `100%`, cursor: `pointer` }}
              />
            )}

            <MobileChoicesBlock answered={answered} mobile>
              <CaseBlock>
                <CaseQuestionAnswer
                  caseObject={caseObject}
                  createMarkup={createMarkup}
                />
                <Suspense fallback={<Loading>Loading....</Loading>}>
                  <CaseChoices
                    caseObject={caseObject}
                    handleChoiceChange={handleChoiceChange}
                    caseHash={props.match.params.caseHash}
                    handleAnswer={handleAnswer}
                    selectedChoice={selectedChoice}
                    answered={answered}
                    correct={correct}
                    user={props.user}
                    {...props}
                  />
                </Suspense>
              </CaseBlock>
            </MobileChoicesBlock>
            <Suspense fallback={<Loading>Loading....</Loading>}>
              <CaseExplanation
                answered={answered}
                caseHash={props.match.params.caseHash}
                caseObject={caseObject}
              />
            </Suspense>
          </MainCaseBlock>

          <ChoicesBlock answered={answered}>
            <CaseBlock>
              <CaseQuestionAnswer
                caseObject={caseObject}
                createMarkup={createMarkup}
              />
              <Suspense fallback={<Loading>Loading....</Loading>}>
                <CaseChoices
                  caseObject={caseObject}
                  handleChoiceChange={handleChoiceChange}
                  caseHash={props.match.params.caseHash}
                  handleAnswer={handleAnswer}
                  selectedChoice={selectedChoice}
                  answered={answered}
                  correct={correct}
                  user={props.user}
                  {...props}
                />
              </Suspense>
            </CaseBlock>
          </ChoicesBlock>

          {!collection && answered && (
            <RelatedCasesContainer>
              <ClinicAd>
                <div className="text top">
                  <div className="title">Clinic</div>
                  <p>
                    A simulated medical clinic. <br />
                    New patient encounters, every week
                  </p>
                </div>

                <img
                  src="https://medzcool.s3-us-west-2.amazonaws.com/clinic/clinic-homepage-thumb-alt.png"
                  alt=""
                />
                <div className="text bottom">
                  <div className="inner">
                    <div className="btn-container">
                      <StyledButton
                        background={`${blue.bright}`}
                        onClick={() => handleClinicAd("Below the fold")}>
                        <strong>GET CLINIC</strong>
                      </StyledButton>
                    </div>
                  </div>
                </div>
              </ClinicAd>

              <h2>Previously Featured Cases</h2>
              {featuredCases.slice(0, 2).map((caseObj) => {
                if (caseObj.id !== caseId) {
                  return (
                    <div
                      className="clinical-case-card-container"
                      key={caseObj.id}>
                      <ClinicalCaseCard
                        caseId={caseObj.id}
                        title={caseObj.title}
                        desc={caseObj.desc}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}

              {featuredCases.slice(2, 4).map((caseObj) => {
                if (caseObj.id !== caseId) {
                  return (
                    <div
                      className="clinical-case-card-container"
                      key={caseObj.id}>
                      <ClinicalCaseCard
                        caseId={caseObj.id}
                        title={caseObj.title}
                        desc={caseObj.desc}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </RelatedCasesContainer>
          )}
        </CaseSubContainer>

        {!collection && (
          <CaseCallToAction caseId={caseId} answered={answered} />
        )}

        {collection && (
          <CaseFooter
            user={props.user}
            caseHash={caseId}
            caseObject={caseObject}
            answered={answered}
            collection={collection}
            {...props}
          />
        )}
      </CaseMainContainer>
    </Fragment>
  );
}

const CaseBlock = styled.div`
  margin: 15px 0;
  background: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 25px;
  position: relative;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
  margin-bottom: 15px;
`;

const RelatedCasesContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  margin: 15px 15px 100px 15px;
  .clinical-case-card-container {
    margin-bottom: 15px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    h2 {
      margin: 30px 15px 25px 15px;
    }
  }
`;

export const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 8px 15px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  background: ${blue.bright};
  color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
`;
const CallToAction = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #333333;
  padding: 15px;
  color: white;
  font-family: futura-pt, sans-serif;
  box-sizing: border-box;
`;
const ClinicAd = styled.div`
  background-color: white;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
  font-size: 18px;
  .text {
    position: absolute;
    width: 100%;
    &.top {
      top: 25px;
      .title {
        font-size: 36px;
        font-weight: ${fontWeight.bold};
      }
    }
    &.bottom {
      bottom: 50px;
      .inner {
        max-width: 450px;
        margin: auto;
        padding: 0 15px;
      }
      .btn-container {
        max-width: fit-content;
        margin: auto;
        margin-top: 15px;
        margin-top: 10px;
        button {
          border-radius: 5px;
        }
      }
    }
  }
  img {
    max-width: 100%;
    padding: 25px 0;
  }
  p {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    .text {
      &.top {
        top: 15px;
      }
      &.bottom {
        bottom: 35px;
      }
    }
  }
`;

const FooterContainer = styled.div`
  z-index: 20;
  padding: 18px 0 32px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f7f7;
  border-top: 1px solid #e7e7e7;
  @media (max-width: 768px) {
    height: 50px;
  }
`;
const CaseMainContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: auto;
  background: ${grayscale.lightGray};
  a {
    color: ${purple.darkLight};
    ${(props) => props.correct && props.answered && `color: ${green.bright};`}
    ${(props) => !props.correct && props.answered && `color: ${red.bright};`}
  }
  .old-case-text {
    line-height: 30px;
    font-family: adobe-text-pro, serif;
    font-size: 18px;
  }
  .question {
    margin-bottom: 10px;
  }
  .public-DraftStyleDefault-block {
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
    .old-case-text {
      font-size: 16px;
      line-height: 25px;
    }
  }
`;
const CaseSubContainer = styled.div`
  padding: 0px;
  max-width: 1200px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 150px;
  ${(props) => !props.answered && `margin-bottom: 100px;`}
`;
const MainCaseBlock = styled.div`
  margin: 0 15px 0 11px;
  display: inline-block;
  width: 60%;
  vertical-align: top;
  @media (max-width: 992px) {
    width: 58.4%;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;
const ChoicesBlock = styled.div`
  display: inline-block;
  width: 36.5%;
  vertical-align: top;
  ${(props) => props.mobile && "display: none"}
  @media (max-width: 992px) {
    width: 38.4%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    ${(props) => props.mobile && "display: block"}
    ${(props) => !props.mobile && "display: none"}
  }
`;
const MobileChoicesBlock = styled.div`
  display: none;
  vertical-align: top;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    display: block;
  }
`;
const ExplanationCaseBlock = styled(CaseBlock)`
  display: none;
  ${(props) => props.answered && `display: block;`}
  img {
    width: 100%;
  }
`;
const TitleCaseBlock = styled(CaseBlock)`
  border-radius: 0;
  margin: 15px 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 99;
  width: 100%;
  background: white;
  min-height: 30px;
  h2 {
    font-family: futura-pt, sans-serif;
    margin: 5px;
    max-width: 1100px;
    margin: auto;
    font-size: 20px;
    @media (max-width: 1200px) {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      max-width: 76%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${(props) =>
    props.correct &&
    props.answered &&
    `background: ${green.bright}; color:white;`}
  ${(props) =>
    !props.correct &&
    props.answered &&
    `background: ${red.bright};color:white;`}
`;
const BackButton = styled.button`
  border: 0;
  color: black;
  padding: 0;
  outline: none;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  cursor: pointer;
  ${(props) => props.answered && `color: white;`}
  @media (max-width: 1200px) {
    display: inline-block;
    position: relative;
    margin: 0;
    transform: none;
    vertical-align: middle;
    left: 0;
    margin: 0 25px 0 15px;
  }
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 16px;
  text-align: center;
  color: gray;
`;
const DoesNotExist = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 50px 15px 15px 15px;
  text-align: center;
  font-family: ${fontFamily.sansSerif};
  h2 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`;
