import React, { useEffect, useState, Fragment } from "react";
import { url, homeUrl } from "../settings";
import ReactGA from "react-ga";
import { loadStripe } from "@stripe/stripe-js";

// Components
import CaseDetails from "../CaseDetails/CaseDetails";
import Spinner from "../Utilities/Spinner";
import CaseCard from "../CaseDetails/CaseCard";
import CollectionFooter from "./CollectionFooter";
import { StyledButton } from "../Utilities";
import Header from "../Header/Header";
import ClinicCallToAction from "../Ads/ClinicCallToAction";

// Dependencies
import axios from "axios";

// Utilities
import { blue, toCurrency, shuffle } from "../Utilities";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily, LoadingContainer } from "../Utilities";
import {
  H1,
  MediumText,
  LargeText,
  SubTitle,
  AnchorTag,
  SmallText,
} from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faPlay } from "@fortawesome/pro-regular-svg-icons";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_Cy67gqjZIC3vQVFaI6aan6X3");
const stripePromise = loadStripe("pk_live_VEK0vCcloCuAbjcW4h4yQcaQ");

export default function Collection(props) {
  const user = props.user;
  const collectionUrl = props.match.params.collectionUrl;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, [props.location.pathname]);

  let userId;
  if (user) {
    userId = user.id;
  } else {
    userId = null;
  }

  const [playingUnused, setPlayingUnused] = useState(false);
  function togglePlayUnused(playingState) {
    setPlayingUnused(playingState);
  }

  useEffect(() => {
    if (playingUnused) {
      window.onpopstate = (e) => {
        setPlayingUnused(false);
      };
    }
  }, []);

  const [loading, setLoading] = useState(true);
  const [collection, setCollection] = useState();
  const [collectionState, setCollectionState] = useState();
  const [cases, setCases] = useState();
  const [casesHashId, setCasesHashId] = useState();
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    initializeCollectionData(source);

    return () => {
      source.cancel("cancelled initializeCollectionData");
    };
  }, []);

  function initializeCollectionData(source) {
    axios({
      method: "post",
      url: `${url}/rounds/fetch-collection/`,
      data: {
        collectionUrl: collectionUrl,
        userId: userId,
      },
      cancelToken: source.token,
    })
      .then(function (response) {
        setCollection(response.data);

        if (collectionUrl == "free-collection") {
          if (!user) {
            window.location = "https://medzcool.com/free-collection";
          }
          if (user) {
            const subscribedRounds = user.profile.subscribedRounds;
            let userHasCollection = subscribedRounds.find(
              (subcribedCollection) => {
                return (
                  subcribedCollection["collectionTitle"] == "Free Collection"
                );
              }
            );
            if (!userHasCollection) {
              window.location = "https://medzcool.com/free-collection";
            }
          }
        }

        let existingCollectionState;
        let compiledCollectionState;
        if (response.data.collectionState) {
          console.log(response.data);
          const jsonCollectionState = JSON.parse(response.data.collectionState);
          console.log(jsonCollectionState);
          setCollectionState(jsonCollectionState);
          existingCollectionState = true;
          compiledCollectionState = jsonCollectionState;
        } else {
          const collectionStateExample = {
            incorrectCases: [],
            correctCases: [],
            unansweredCases: [],
            answeredCases: [],
            flaggedCases: [],
          };
          setCollectionState(collectionStateExample);
          existingCollectionState = false;
          compiledCollectionState = collectionStateExample;
        }

        const data = {
          collection: response.data,
          existingCollectionState: existingCollectionState,
          compiledCollectionState: compiledCollectionState,
        };
        return data;
      })
      .then(function (response) {
        const prevResponse = response;
        axios({
          method: "post",
          url: `${url}/cases/fetch-collection/`,
          data: {
            collectionId: prevResponse.collection.codehealthId,
          },
          cancelToken: source.token,
        }).then(function (response) {
          const allCases = response.data.collection.case_set;
          let allCasesHash = [];
          allCases.map((caseObj) => {
            if (caseObj.hashid) {
              allCasesHash.push(caseObj.hashid);
            }
          });
          setCasesHashId(allCasesHash);

          setCases(shuffle(allCases));
          setLoading(false);

          const newCollectionState = {
            ...prevResponse.compiledCollectionState,
          };

          const randomUnansweredCases = shuffle(
            newCollectionState.unansweredCases
          );

          // if new collection state, push all cases into unanswered
          // new collection state pushed to database when user answers a case
          if (!prevResponse.existingCollectionState) {
            const newCollectionState = {
              ...prevResponse.compiledCollectionState,
            };
            response.data.collection.case_set.map((caseObj) => {
              if (caseObj.hashid) {
                randomUnansweredCases.push(caseObj.hashid);
              }
            });
            setCollectionState(newCollectionState);

            // if a collection state exists
            // compare existing state (answered/unanswered/correct/incorrert) with cases state
          } else {
            // 1. Create a new collection state object
            const newCollectionState = {
              ...prevResponse.compiledCollectionState,
            };

            const intersectingAnswered = newCollectionState.answeredCases.filter(
              (caseHash) => {
                return allCasesHash.includes(caseHash);
              }
            );

            const intersectingUnanswered = allCasesHash.filter((caseHash) => {
              return !intersectingAnswered.includes(caseHash);
            });
            // console.log(intersectingUnanswered);

            const shuffledUnanswered = shuffle(intersectingUnanswered);

            const intersectingCorrect = newCollectionState.correctCases.filter(
              (caseHash) => {
                return allCasesHash.includes(caseHash);
              }
            );
            // console.log(intersectingCorrect);

            const intersectingIncorrect = newCollectionState.incorrectCases.filter(
              (caseHash) => {
                return allCasesHash.includes(caseHash);
              }
            );
            // console.log(intersectingIncorrect);

            const parsedCollectionState = {
              unansweredCases: shuffledUnanswered,
              answeredCases: intersectingAnswered,
              incorrectCases: intersectingIncorrect,
              correctCases: intersectingCorrect,
            };
            setCollectionState(parsedCollectionState);
          }
        });
      });
  }

  function postCollectionState(newCollectionState) {
    axios
      .post(`${url}/rounds/handle-collection-state/`, {
        codehealthKey: "medzcool-portal-key",
        userId: userId,
        collectionId: collection.collectionId,
        collectionState: JSON.stringify(newCollectionState),
      })
      .then(function (response) {
        // console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleCaseAnswerState(answer, caseId) {
    const newCollectionState = { ...collectionState };
    if (!newCollectionState.answeredCases.includes(caseId) && user) {
      newCollectionState.answeredCases.push(caseId);

      const newUnanswered = newCollectionState.unansweredCases.filter(
        (caseObj) => caseObj !== caseId
      );

      newCollectionState.unansweredCases = newUnanswered;

      if (answer == "correct") {
        newCollectionState.correctCases.push(caseId);
      }
      if (answer == "incorrect") {
        newCollectionState.incorrectCases.push(caseId);
      }

      setCollectionState(newCollectionState);

      postCollectionState(newCollectionState);
    }
  }
  function handleFlaggedCase(flagState, caseId) {
    const newCollectionState = { ...collectionState };
    if (!newCollectionState.flaggedCases) {
      newCollectionState["flaggedCases"] = [];
    }

    if (flagState === "add") {
      newCollectionState.flaggedCases.push(caseId);
    }

    let newFlaggedCases;
    if (flagState === "remove") {
      newFlaggedCases = newCollectionState.flaggedCases.filter(
        (caseObj) => caseObj !== caseId
      );
    }
    newCollectionState.flaggedCases = newFlaggedCases;

    setCollectionState(newCollectionState);

    postCollectionState(newCollectionState);
  }

  const [purchase, setPurchase] = useState(false);
  useEffect(() => {
    if (props.purchase) {
      setPurchase(true);
    }
    return () => { };
  }, []);

  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
    if (collection && user) {
      const subscribedRounds = user.profile.subscribedRounds;
      subscribedRounds.map((subcribedCollection) => {
        if (subcribedCollection.collectionTitle == collection.collectionTitle) {
          setIsSubscribed(true);
        }
      });
      if (user.profile.subscriptionStatus == "active") {
        setIsSubscribed(true);
      }
    }
  }, [collection]);

  const { collectionCardSessionId } = props;
  useEffect(() => {
    if (!isSubscribed) {
      setSessionIdAlt(collectionCardSessionId);
    }
  }, [isSubscribed]);

  // This triggers UI change to the confirm purchase component
  // false - UI is promo page
  // true - user has clicked 'buy', UI now asks user to confirm/proceed
  // const [confirmingPrice, setConfirmingPrice] = useState(false);

  const [sessionIdAlt, setSessionIdAlt] = useState();
  const [fetchingCheckout, setFetchingCheckout] = useState(false);
  function fetchCheckoutSession() {
    setFetchingCheckout(true);
    return fetch(`${url}/rounds/create-payment-session/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        price_id: collection.price_id,
        customer: props.user.profile.stripeCustomerId,
        userId: props.user.id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result.error) {
          console.log(result.error);
          throw result;
        }
        return { sessionId: result.id };
      });
  }

  function getFreeCollection() {
    console.log("get free collection");
    fetch(`${url}/rounds/get-free-collection/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: props.user.id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        window.location.reload();
        return;
      });
  }

  const handleCheckout = async (event) => {
    // Call your backend to create the Checkout session.
    const { sessionId } = await fetchCheckoutSession();
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe
      .redirectToCheckout({
        sessionId,
      })
      .then((result) => {
        setFetchingCheckout(false);
        return result;
      });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };
  function handleCancelCheckout() {
    setSessionIdAlt();
  }

  function renderButton() {
    if (isSubscribed) {
      return null;
    } else {
      if (collectionUrl === "free") {
        return (
          <StyledButton
            active
            onClick={getFreeCollection}
            borderRadius={"5px;"}
            background={"#295AEC"}
            color={collection.isSale ? "white" : "black"}
            boxShadow={"1px 3px 10px 2px rgba(0,0,0,0.25);"}
            fontWeight={"700"}>
            GET FREE COLLECTION
          </StyledButton>
        );
      }
      return (
        <StyledButton
          active
          onClick={() =>
            (window.location = `https://medzcool.com/collection/checkout/${collectionUrl}`)
          }
          borderRadius={"5px;"}
          background={"#295AEC"}
          color={collection.isSale ? "white" : "black"}
          boxShadow={"1px 3px 10px 2px rgba(0,0,0,0.25);"}
          fontWeight={"700"}>
          BUY NOW
        </StyledButton>
      );
    }
  }

  function startCollection() {
    props.history.push(
      "/legacy-collection/" +
      props.match.params.collectionUrl +
      "/" +
      collectionState.unansweredCases[0]
    );
    togglePlayUnused(true);
  }

  function nextCase() {
    props.history.push(
      "/collection/" +
      props.match.params.collectionUrl +
      "/" +
      collectionState.unansweredCases[0]
    );
  }
  const [confirmReset, setConfirmReset] = useState(false);
  function resetCollectionState() {
    const randomUnansweredCases = shuffle(casesHashId);
    const collectionStateExample = {
      incorrectCases: [],
      correctCases: [],
      unansweredCases: randomUnansweredCases,
      answeredCases: [],
    };

    axios
      .post(`${url}/rounds/handle-collection-state/`, {
        codehealthKey: "medzcool-portal-key",
        userId: userId,
        collectionId: collection.collectionId,
        collectionState: JSON.stringify(collectionStateExample),
      })
      .then(function (response) {
        console.log(response.data);
        setCollectionState(collectionStateExample);
      })
      .catch(function (error) {
        console.log(error);
      });
    setConfirmReset(false);
  }

  function renderPerformance() {
    const incorrect = collectionState.incorrectCases.length;
    const correct = collectionState.correctCases.length;
    const answered = collectionState.answeredCases.length || null;

    const incorrectPercentage = Math.round((incorrect / answered) * 100) || 0;
    const correctPercentage = Math.round((correct / answered) * 100) || 0;

    return (
      <Fragment>
        <CorrectPerformance
          className="correct"
          width={`${correctPercentage}` + "%"}>
          {`${correctPercentage}` + "%"}
        </CorrectPerformance>
        <IncorrectPerformance
          className="incorrect"
          width={`${incorrectPercentage}` + "%"}>
          {`${incorrectPercentage}` + "%"}
        </IncorrectPerformance>
      </Fragment>
    );
  }

  if (loading)
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );

  if (confirmReset) {
    return (
      <Fragment>
        <Header user={user} onCollectionPage />
        <CollectionContainer>
          <CollectionNav>
            <div className="img-container">
              {!isSubscribed && collection.isSale && (
                <div className="sale-tag">On SALE</div>
              )}
              <img src={collection.collectionThumbnail} />
            </div>
            <H1 className="collection-title">{collection.collectionTitle}</H1>

            {!isSubscribed && (
              <div className="meta">
                <MediumText className="inline">&middot;</MediumText>
                <MediumText className="inline">
                  {collection.collectionCaseCount + " cases"}
                </MediumText>
              </div>
            )}

            <MediumText>{collection.collectionDescription}</MediumText>
          </CollectionNav>

          <div>
            <LargeText>
              <strong>Do you really want to reset this collection?</strong>
            </LargeText>
            <div className="buttons-container">
              <StyledButton
                className="cancel"
                onClick={() => setConfirmReset(false)}>
                Cancel
              </StyledButton>

              <StyledButton
                className="proceed"
                active
                onClick={resetCollectionState}
                borderRadius={"5px;"}
                boxShadow={"1px 3px 10px 2px rgba(0,0,0,0.25);"}
                color={"white"}
                background={`${blue.bright}`}
                fontWeight={"700"}>
                Reset Collection
              </StyledButton>
            </div>
          </div>
        </CollectionContainer>
      </Fragment>
    );
  }


  if (props.match.params.caseId && isSubscribed) {
    return (
      <Fragment>
        <CaseDetails
          {...props}
          user={props.user}
          collection={collection}
          handleCaseAnswerState={handleCaseAnswerState}
          nextCase={nextCase}
          collectionState={collectionState}
          playingUnused={playingUnused}
          togglePlayUnused={togglePlayUnused}
        />
      </Fragment>
    );
  } else if (props.match.params.caseId && !isSubscribed) {
    window.location = "https://rounds.medzcool.com";
    return null;
  }

  return (
    <Fragment>
      <Header user={user} onCollectionPage />
      <CollectionContainer>
        <CollectionNav>
          <div className="img-container">
            {!isSubscribed && collection.isSale && (
              <div className="sale-tag">On SALE</div>
            )}
            <img src={collection.collectionThumbnail} />
          </div>

          <H1 className="collection-title">
            {collection.collectionTitle}
            <div className="case-count">
              {collection.collectionCaseCount + " cases"}
            </div>
          </H1>

          {!isSubscribed && (
            <Fragment>
              <div className="meta">
                {!collection.isSale ? (
                  <Fragment>
                    <MediumText className="inline">
                      {`$${toCurrency(collection.collectionPrice)}`}
                    </MediumText>
                  </Fragment>
                ) : (
                  <Fragment>
                    <MediumText className="inline strikethrough">
                      {`$${toCurrency(collection.collectionPrice)}`}
                    </MediumText>
                    <MediumText className="inline sale-price">
                      {"$" + toCurrency(collection.collectionSalePrice)}
                    </MediumText>
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}

          <MediumText>{collection.collectionDescription}</MediumText>
          <div className="button-container">{renderButton()}</div>

          {collectionState.answeredCases.length > 0 && (
            <div className="nav-block">
              <MediumText>
                <strong>Performance</strong>
                <div>{renderPerformance()}</div>
              </MediumText>
            </div>
          )}

          {isSubscribed && collectionState.unansweredCases.length > 0 && (
            <div className="collection-actions">
              <StyledButton
                active
                onClick={startCollection}
                className="action-button"
                boxShadow={"0px 5px 5px rgba(0,0,0,0.1);"}
                borderRadius={"4px;"}
                color={"white"}
                background={`${blue.bright}`}>
                <FontAwesomeIcon icon={faPlay} />
                <span className="text-right">Play Unused</span>
              </StyledButton>
            </div>
          )}
          {isSubscribed && collectionState.answeredCases.length > 0 && (
            <StyledButton
              active
              onClick={() => setConfirmReset(true)}
              className="action-button"
              background={"none;"}>
              <FontAwesomeIcon icon={faUndo} />
              <span className="text-right">Reset Collection</span>
            </StyledButton>
          )}
        </CollectionNav>

        <div>
          {collectionState.unansweredCases.length > 0 && (
            <CaseSection>
              <LargeText className="case-section-title">
                Unused Cases
              </LargeText>
              {cases.map((caseObj) => {
                if (
                  !collectionState.answeredCases.includes(caseObj.hashid) &&
                  caseObj.hashid
                ) {
                  return (
                    <CaseCard
                      key={caseObj.id}
                      caseObj={caseObj}
                      collection={collection}
                      collectionUrl={collectionUrl}
                      redirectUrl={`/legacy-collection/${collectionUrl}`}
                    />
                  );
                }
              })}
            </CaseSection>
          )}

          {collection.collectionTitle == "Free Collection" && (
            <ClinicCallToAction />
          )}

          {collectionState.incorrectCases.length > 0 && (
            <CaseSection>
              <LargeText className="case-section-title top-margin">
                Incorrect
              </LargeText>
              {cases.map((caseObj) => {
                if (
                  collectionState.incorrectCases.includes(caseObj.hashid) &&
                  caseObj.hashid
                ) {
                  return (
                    <CaseCard
                      key={caseObj.id}
                      caseObj={caseObj}
                      collection={collection}
                      collectionUrl={collectionUrl}
                      redirectUrl={`/legacy-collection/${collectionUrl}`}
                    />
                  );
                }
              })}
            </CaseSection>
          )}

          {collectionState.correctCases.length > 0 && (
            <CaseSection>
              <LargeText className="case-section-title top-margin">
                Correct
              </LargeText>
              {cases.map((caseObj) => {
                if (
                  collectionState.correctCases.includes(caseObj.hashid) &&
                  caseObj.hashid
                ) {
                  return (
                    <CaseCard
                      key={caseObj.id}
                      caseObj={caseObj}
                      collection={collection}
                      collectionUrl={collectionUrl}
                      redirectUrl={`/legacy-collection/${collectionUrl}`}
                    />
                  );
                }
              })}
            </CaseSection>
          )}
        </div>

        {/* {sessionIdAlt && props.user ? (
          <>
            {fetchingCheckout ? (
              <Spinner />
            ) : (
              <div>
                <LargeText>
                  Confirm purchase of{" "}
                  <strong>{collection.collectionTitle}</strong> for{" "}
                  <strong>
                    {" "}
                    {collection.isSale
                      ? `$${toCurrency(collection.collectionSalePrice)}`
                      : `$${toCurrency(collection.collectionPrice)}`}{" "}
                  </strong>
                  ?
                </LargeText>
                <div className="buttons-container">
                  <StyledButton
                    className="cancel"
                    onClick={handleCancelCheckout}>
                    Cancel
                  </StyledButton>
                  <StyledButton
                    className="proceed"
                    active
                    onClick={handleCheckout}
                    borderRadius={"5px;"}
                    boxShadow={"1px 3px 10px 2px rgba(0,0,0,0.25);"}
                    background={"#295AEC"}
                    color={collection.isSale ? "white" : "black"}
                    fontWeight={"700"}>
                    PROCEED TO CHECKOUT
                  </StyledButton>
                </div>
              </div>
            )}
          </>
        ) : isSubscribed ? (
          <div>
            {collectionState.unansweredCases.length > 0 && (
              <CaseSection>
                <LargeText className="case-section-title">
                  Unused Cases
                </LargeText>
                {cases.map((caseObj) => {
                  if (
                    !collectionState.answeredCases.includes(caseObj.hashid) &&
                    caseObj.hashid
                  ) {
                    return (
                      <CaseCard
                        key={caseObj.id}
                        caseObj={caseObj}
                        collection={collection}
                        collectionUrl={collectionUrl}
                        redirectUrl={`/legacy-collection/${collectionUrl}`}
                      />
                    );
                  }
                })}
              </CaseSection>
            )}

            {collection.collectionTitle == "Free Collection" && (
              <ClinicCallToAction />
            )}

            {collectionState.incorrectCases.length > 0 && (
              <CaseSection>
                <LargeText className="case-section-title top-margin">
                  Incorrect
                </LargeText>
                {cases.map((caseObj) => {
                  if (
                    collectionState.incorrectCases.includes(caseObj.hashid) &&
                    caseObj.hashid
                  ) {
                    return (
                      <CaseCard
                        key={caseObj.id}
                        caseObj={caseObj}
                        collection={collection}
                        collectionUrl={collectionUrl}
                        redirectUrl={`/legacy-collection/${collectionUrl}`}
                      />
                    );
                  }
                })}
              </CaseSection>
            )}

            {collectionState.correctCases.length > 0 && (
              <CaseSection>
                <LargeText className="case-section-title top-margin">
                  Correct
                </LargeText>
                {cases.map((caseObj) => {
                  if (
                    collectionState.correctCases.includes(caseObj.hashid) &&
                    caseObj.hashid
                  ) {
                    return (
                      <CaseCard
                        key={caseObj.id}
                        caseObj={caseObj}
                        collection={collection}
                        collectionUrl={collectionUrl}
                        redirectUrl={`/legacy-collection/${collectionUrl}`}
                      />
                    );
                  }
                })}
              </CaseSection>
            )}
          </div>
        ) : (
          <div>
            <CaseSection className="top">
              {cases.map((caseObj) => {
                if (caseObj.hashid) {
                  return (
                    <CaseCard
                      key={caseObj.id}
                      caseObj={caseObj}
                      collection={collection}
                      collectionUrl={collectionUrl}
                      locked
                    />
                  );
                }
              })}
            </CaseSection>
          </div>
        )} */}
      </CollectionContainer>
    </Fragment>
  );
}

const CollectionContainer = styled.div`
  padding: 15px;
  margin: auto;
  margin-top: 75px;
  margin-bottom: 150px;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 50px;
  max-width: 1200px;
  a {
    text-decoration: none;
  }
  .buttons-container {
      display: grid;
      grid-template-columns: 1fr 2fr
      grid-gap: 20px;
      margin-top: 20px;
  }
  @media(max-width:768px) {
      grid-template-columns: 1fr 1.6fr;
      grid-gap: 30px;
  }
  @media(max-width:500px) {
      grid-template-columns: 1fr;
      grid-gap: 80px;
      margin-top: 50px;
      padding: 10px;
  }
`;
const CorrectPerformance = styled.div`
  display: inline-block;
  background: #b5ffee;
  padding: 5px;
  text-align: center;
  margin-top: 5px;
  ${(props) => props.width && `width: ${props.width}`};
`;
const IncorrectPerformance = styled.div`
  display: inline-block;
  background: #ffb8d2;
  padding: 5px;
  text-align: center;
  margin-top: 5px;
  ${(props) => props.width && `width: ${props.width}`};
`;
const CollectionNav = styled.div`
  .nav-block {
    margin: 50px 0 50px 0;
  }
  .collection-title {
    .case-count {
      font-size: 13px;
      color: gray;
      font-weight: 300;
    }
  }
  .button-container {
    margin: 25px 0 8px 0;
  }
  .img-container {
    position: relative;
    .sale-tag {
      position: absolute;
      background: #fd2c9b;
      padding: 8px 15px;
      top: 15px;
      left: -10px;
      color: white;
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }
  img {
    width: 100%;
  }

  .meta {
    margin: 10px 0;
    .strikethrough {
      text-decoration: line-through;
      font-weight: 300;
    }
    .sale-price {
      background: #fd2c9b;
      padding: 3px 15px;
      top: 15px;
      width: fit-content;
      color: white;
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }
  .inline {
    display: inline-block;
    margin-right: 10px;
  }
  .collection-actions {
    .action-button {
      margin-top: 10px;
    }
  }
  @media (max-width: 500px) {
    .collection-title {
      margin: 12px 0;
    }
    .nav-block {
      margin: 30px 0 30px 0;
    }
  }
`;

const CaseContainer = styled.div`
  padding: 25px;
  margin: 10px 0;
  background: ${grayscale.lightGray};
  font-family: ${fontFamily.sansSerif};
`;
const CaseSection = styled.div`
  margin-bottom: 50px;
  .case-section-title {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    .case-section-title {
      font-size: 22px;
    }
  }
`;
